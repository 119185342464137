import Script from 'next/script';
import React from 'react';

import { useBooleanFlags } from '@/domains/core/flags/flags.hooks';
import { getAppSetting } from '@/domains/core/settings/appSettings';
import { TRUSTED_SHOPS_ENABLED } from '@/domains/core/trustedShops/constants';

export const TrustBadge: React.FC = () => {
  const [trustBadgeEnabled] = useBooleanFlags([TRUSTED_SHOPS_ENABLED]);
  const tsID = getAppSetting('TRUSTED_SHOPS_ID');

  if (!trustBadgeEnabled) {
    return null;
  }

  return (
    <Script
      async
      strategy="lazyOnload"
      data-desktop-y-offset="-30"
      data-mobile-y-offset="34"
      data-desktop-disable-reviews="false"
      data-desktop-enable-custom="false"
      data-desktop-position="left"
      data-desktop-enable-fadeout="true"
      data-mobile-disable-reviews="false"
      data-mobile-enable-custom="false"
      data-mobile-position="left"
      src={`//widgets.trustedshops.com/js/${tsID}.js`}
    />
  );
};
