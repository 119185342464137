import { translateFormatter } from '@/domains/core/localization/translate';
import type { CommercialOperationInfo, CommercialOperationInfoFromApi } from '@/domains/productDiscovery/CommercialAnimation/CommercialAnimation.model';
import { NB_OF_DAYS_TIME_LEFT_TO_DISPLAY_LABEL, NB_OF_HOURS_TO_DISPLAY_IN_DAYS, ONE_DAY_IN_MILLISECONDS, ONE_HOUR_IN_MILLISECONDS } from '@/domains/productDiscovery/Listings/constants';
export function processRetailPriceTimeLeftLabel(endAt?: number | null): string | null {
  if (!endAt) {
    return null;
  }
  const endDate = endAt > 1000000000000 ? endAt / 1000 : endAt;
  const now = Date.now();
  const diffTime = Math.abs(endDate * 1000 - now) || 0;
  const diffHours = Math.ceil(diffTime / ONE_HOUR_IN_MILLISECONDS);
  const diffDays = Math.ceil(diffTime / ONE_DAY_IN_MILLISECONDS);
  const label = translateFormatter<{
    timeType: 'hour' | 'day';
    number: number;
  }>("{timeType, select,\n    hour {endet in {number} Stunden}\n    other {endet in {number} Tagen}\n  }", undefined, undefined, "de");
  return diffHours > 0 && diffHours <= NB_OF_DAYS_TIME_LEFT_TO_DISPLAY_LABEL * 24 ? label.format({
    timeType: diffHours > NB_OF_HOURS_TO_DISPLAY_IN_DAYS ? 'day' : 'hour',
    number: diffHours > NB_OF_HOURS_TO_DISPLAY_IN_DAYS ? diffDays : diffHours
  }) as string : null;
}
export const processCommercialOperations = (commercialOperations: CommercialOperationInfoFromApi[]): CommercialOperationInfo[] => commercialOperations.map(coFromApi => ({
  id: coFromApi.id,
  endAt: coFromApi.endAt ? coFromApi.endAt : null,
  retailPriceTimeLeftLabel: processRetailPriceTimeLeftLabel(coFromApi.endAt)
}));